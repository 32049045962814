import './App.css';
import Navbar from './Components/Navbar';
import { Routes, Route } from 'react-router-dom';
// import About from './Pages/About';
import Contact from './Pages/Contact';
import Donate from './Pages/Donate';
import Home from './Pages/Home';
import Services from './Pages/Services';
import Photos from './Pages/Photos';
import NotFound from './Pages/NotFound';

function App() {
  return (
    <div>
      
      <Navbar />
      <div className="pages">
        <Routes>
          <Route path = '/' element={<Home />}></Route>
          {/* <Route path = "/about" element={<About />}></Route> */}
          <Route path = "/services" element={<Services />}></Route>
          <Route path = "/contact" element={<Contact />}></Route>
          <Route path = "/donate" element={<Donate />}></Route>
          <Route path = "/photos" element={<Photos />}></Route>
          <Route path = "*" element={<NotFound />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;