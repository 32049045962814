import React from 'react';
import donate from '../Images/donate.jpeg';
import '../Pages-css/donate.css'

function Donate() {
  
  return (
    <div className='App'>
      <div className='donate-container'>
        <img src={donate} alt="" className='donate-img'/>
        <div className="donate-box">
          <h3 className='donate-h3'>DONATE NOW</h3>
          <p className='donate-p'>Take a step now, let's build a better future for each of us together.</p>
        </div>
      </div>
      <div className="donate-container2">
        <div className="donate-box2">
          <p className='donate-p'>Merchant Name</p>
          <p className='donate-p2'>M/S. SHIV HANUMAT KRIPA APNA ASHRAM TRUST</p>
        </div>
        <div className="donate-box2">
          <p className='donate-p'>UPI ID</p>
          <p className='donate-p2'>shivhanumatkripaapnaashramtrust@icici</p>
        </div>
        <div className="donate-box2">
          <p className='donate-p'>SHIV HANUMAT KRIPA APNA ASHRAM TRUST</p>
          <p className='donate-p2'>AC NO : 21830 100 1966</p>
          <p className='donate-p2'>IFC code : ICIC0002183</p>
          <p className='donate-p2'>ICICI BANK</p>
          <p className='donate-p2'>शिव हनुमत कृपा अपना आश्रम ट्रस्ट , संस्था के इस AC में सेवा के लिए दान किया गया धन 80G , 12A के तहत  टैक्स फ्री होगा 🌺🌺🙏🏼🙏🏼🕉️</p>
        </div>
        <div className="donate-box2">
          <p className='donate-p'>PHONE PE NUMBER</p>
          <p className='donate-p2'>9599387743 </p>
          <p className='donate-p2'>में  भी सहयोग कर  सकते हैं 🙏🏼🙏🏼🌺</p>
        </div>
      </div>
    </div>
  )
}

export default Donate