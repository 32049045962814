import React from 'react'
import './SocialService.css'

function SocialService(props) {
  return (
    <div className='container'>
        <div className="box">
          <img src={props.img} alt="" className="center"/>
        </div>
        <div className='container2'>
          <h5>{props.text1}</h5>
          <h5>{props.text2}</h5>
          <p>{props.text3}</p>
        </div>
    </div>
  )
}

export default SocialService