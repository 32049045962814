import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCd239X5IVwGAmgBt9n3onbLY21npcyBb4",
  authDomain: "apna-ashram.firebaseapp.com",
  projectId: "apna-ashram",
  storageBucket: "apna-ashram.appspot.com",
  messagingSenderId: "564967911817",
  appId: "1:564967911817:web:948a37d7263439b8e6f158"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);