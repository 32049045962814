import React from 'react'
import SocialWelfareText from './SocialWelfareText'

function SocialWelfare(props) {
  return (
    <div className='container'>
        <div>
            <img src={props.img} alt="" className="center"/>
        </div>
        <div>
            <SocialWelfareText 
                steps="Step 1"
                special="आश्रम में साधकों के लिए रहने और खाने कि सुविधा फ्री होगी"
                text1="1) भजन, सत्संग ,कीर्तन के लिए  , सत्संग भवन"
                text2="2) भंडारा के लिए हॉल"
                text3="3) मंदिर में नर्मदेश्वर महादेव की स्थापना हो गई है"
                text4="4)राम दरबार व हनुमान जी मंदिर बन रहा है"
                text5="5)  भंडारा कक्ष व रसोई घर "
            />
            <SocialWelfareText 
                steps="Step 2"
                text1="1)  हनुमान जी मंदिर की स्थापना"
                text2="2)  राम नाम जप और कीर्तन के लिए 55×40 के होल की स्थापना हो गई है"
                text3="3)  देवी स्वरूप महिला साधकों  के लिए विश्राम कक्ष"
                text4="4)  पुरुष साधक भाईयों के लिए विश्राम कक्ष"
            />
            <SocialWelfareText 
                steps="Step 3"
                text1="1) पारदेश्वर शिव लिंग व श्री यंत्र स्थापना के लिए होल व मंदिर की स्थापना।"
                text2="2) 108 किलो के पारदेश्वर शिव लिंग की स्थापना ।"
                text3="3)  माता मंदिर में 5 फीट 2 इंच के श्री यंत्र की स्थापना ।"
                text4="4) भगवान शिव व मां जगदम्बा की साकार रूप में स्थापना।"
                text5="5) मां ज्वाला जी से लाकर अखंड ज्योति स्थापना ।"
            />
        </div>
    </div>
  )
}

export default SocialWelfare