import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from '../Images/Img12.jpeg';
import img2 from '../Images/Img13.jpeg';
import img3 from '../Images/Img10.jpeg';
import img4 from '../Images/Img11.jpeg';
import './Imageslider.css'

function Imageslider() {
  return (
    <div className='App'>
        <AliceCarousel autoPlay autoPlayInterval="1500" infinite disableButtonsControls responsive="items:2" className="car-style">
            <img src={img1} alt='Image 1' className='sliderimg'></img>
            <img src={img2} alt='Image 2' className='sliderimg'></img>
            <img src={img3} alt='Image 3' className='sliderimg'></img>
            <img src={img4} alt='Image 4' className='sliderimg'></img>
        </AliceCarousel>
        
    </div>
  )
}

export default Imageslider