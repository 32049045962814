import {React, useState, useEffect} from 'react';
import  '../Pages-css/photos.css';

import {storage} from "../Firebase/Config";
import { ref, getDownloadURL, listAll } from "firebase/storage";

function Photos() {

    const [imgUrl, setImgUrl] = useState([]);

    useEffect(() => {
        listAll(ref(storage, "photos")).then((imgs) => {
          const urls = [];
          const promises = imgs.items.map((val) =>
            getDownloadURL(val).then((url) => {
              urls.push(url);
            })
          );
          Promise.all(promises).then(() => {
            setImgUrl(urls);
          });
        });
      }, []);

  return (
    <div className="photos-page">
      <header className="page-header">
        <h1>All Photos</h1>
      </header>

      <div className="photo-grid">
        {imgUrl.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo ${index + 1}`} style={{height: "300px"}}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Photos