import React from 'react';
import './SocialService.css';

function SocialWelfareText(props) {
  return (
    <div>
        <div className='container2'>
            <h5 className='h5-style'>{props.steps}</h5>
            <h5 className='h5-style'>{props.special}</h5>
            <h5 className='h5-style'>{props.text1}</h5>
            <h5 className='h5-style'>{props.text2}</h5>
            <h5 className='h5-style'>{props.text3}</h5>
            <h5 className='h5-style'>{props.text4}</h5>
            <h5 className='h5-style'>{props.text5}</h5>
        </div>
    </div>
  )
}

export default SocialWelfareText