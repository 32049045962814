import React from 'react'
import Imageslider from '../Components/Imageslider'
import '../Pages-css/home.css'

function Home() {
  return (
    <div>
      <div className='home-container'>
        <h1 className='home-h1'>Apna Ashram</h1>
        <Imageslider/>
        <h1>About </h1>
      </div>
    </div>
  )
}

export default Home