import React from 'react'
import '../Pages-css/contact.css'

function Contact() {
  return (
    <div>
      <div className="contact-container">
        <h2>Contact Us</h2>
        <br />
        <h4>नमः शिवाय भैया जी 🙏🏼🙏🏼</h4>

        <div className="contact-phone">
          <p className='donate-p' style={{wordBreak:"break-word", fontSize:"38px"}}>Address your communications to: </p>
          <p className="donate-p2">Name : Dinesh Chaudhary</p>
          <p className="donate-p2">Mobile : +91 98181 97743</p>
          {/* <br /> */}
          <hr style={{margin:"15px 15px", border:"2px #C63D2F solid"}}/>
        </div>
        <div className="contact-address">
          <p className='donate-p'>Address : </p>
          <p className='donate-p2'>शिव हनुमत कृपा अपना आश्रम ट्रस्ट, जैत भर्तिया रोड( OPP, दिल्ली मथुरा रोड जैत गांव ) vill. Bhrtiya near Power  House वृन्दावन धाम मथुरा (UP)</p>
          <br />
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3535.5769666418623!2d77.61665987546378!3d27.606642076240416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDM2JzIzLjkiTiA3N8KwMzcnMDkuMyJF!5e0!3m2!1sen!2sin!4v1708761981362!5m2!1sen!2sin" title='Location' className='contact-map'></iframe>
          {/* <br /> */}
          <hr style={{margin:"15px 15px", border:"2px #C63D2F solid"}}/>
        </div>
      </div>
    </div>
  )
}

export default Contact