import React from 'react'
import SocialService from '../Components/SocialService'
import img9 from '../Images/Img9.jpeg'
import img7 from '../Images/Img7.jpeg'
import '../Components/SocialService.css'
import SocialWelfare from '../Components/SocialWelfare'

function Services() {
  return (
    <div>
      <h1 className="services-style">MEET APNA ASHRAM</h1>
      <h2 className="services-style2" style={{textDecoration:"underline 5px"}}>Social Service</h2>
      <div>
        <SocialService 
          img={img9} 
          text1="1)  गरीब व बेसहारा लोगों के लिए चिकितशालय की फ्री सुविधा" 
          text2="2)  गरीब बेटियो व दिव्यांग बेटियो की पढ़ाई व शादी का खर्चा आश्रम अपनी सुविधा अनुसार करेगा।"
          text3="नोट:  सभी सुविधाएं प्रभु के  आशीर्वाद व प्रभु ईच्छा अनुसार होगी।"
        />
      </div>
      <h2 className="services-style2" style={{textDecoration:'underline 5px'}}>Social Welfare</h2>
      <div className="main_container">
        <SocialWelfare 
          img={img7}
        />
      </div>

    </div>
  )
}

export default Services